
import { computed, defineComponent, onMounted, ref, watchEffect, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import UserService from "@/core/services/UserService";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Multiselect from "@vueform/multiselect";
import { roles as role } from "@/store/stateless/store";
import CustomerService from "@/core/services/car/CustomerService";
import { Mutations } from "@/store/enums/StoreEnums";

interface EditUserBodyParam {
  id: number;
  name: any;
  customerCount: boolean;
  email: any;
  official_email: any;
  role_id: any;
  is_round_robin: any;
  other_mobile_number: any;
  office_mobile_number: any;
  password: any;
  password_confirmation: any;
  policy_agent_id: any;
  assign_en_leads: any;
  assign_ar_leads: any;
  is_new: any;
  is_renewal: any;
  is_llr: any;
  is_all_deals: any;
  assign_car: any;
  assign_health: any;
  assign_misc: any;
  status: any;
  skip_round: any;
  is_both: any;
  agents: any;
  team_leader: any;
  is_archived: any;
  agentCount: any;
  roles?: any;
  is_underwriter:any;
  misc_insurance_types:any;
}

interface Agent {
  value: number;
  label: string;
}

export default defineComponent({
  name: "Edit-User",
  props: {},
  components: { Multiselect },
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    let roleValue = ref<string>("");
    let roundRobinValue = ref<any>("0");
    let showInactiveTransfer = ref<boolean>(false);
    const miscSelectedVal = ref<any>([]);
    // let renewalValue = ref<string>("");

    let editUserBodyParam = {} as EditUserBodyParam;
    editUserBodyParam = {
      id: -1,
      name: "",
      email: "",
      official_email: "",
      customerCount: false,
      role_id: 0,
      password: "",
      password_confirmation: "",
      other_mobile_number: "",
      office_mobile_number: "",
      is_round_robin: "",
      policy_agent_id: 0,
      assign_en_leads: "",
      assign_ar_leads: "",
      is_new: "",
      is_renewal: "",
      is_llr: "",
      is_all_deals: "",
      assign_car: 0,
      assign_health: 0,
      assign_misc: 0,
      status: "",
      skip_round: "",
      is_both: 0,
      agents: [],
      team_leader: 0,
      is_archived: 1,
      agentCount: 0,
      roles: [],
      is_underwriter:0,
      misc_insurance_types:[]
    };
    const saleagent = ref<Array<Agent>>([]);
    onMounted(async() => {
      toFirst()
      if(!role.users.includes(role.userId())) window.location.href = '/#/404'
      // console.log("-------------------");
      // console.log(editUserObj);

      if (!editUserObj.value.round_robin_setting) {
        editUserObj.value.round_robin_setting = {
          assign_ar_leads: null,
          assign_car: null,
          assign_en_leads: null,
          assign_health: null,
          assign_misc: null,
          is_all_deals: null,
          is_llr: null,
          is_new: null,
          is_renewal: null,
          is_round_robin: null,
          skip_round: null,
          user_id: editUserObj.value.id,
          misc_insurance_types: []
        };
      }

      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Edit User", ["Users"]);

      UserService.getRoles();
      UserService.getUnderWriters();
      await UserService.fetchUser(route.params.userId);
      
      UserService.getAgentBySetting({
        agent_id: route.params.userId
      }).then((result) => {
        saleagent.value = result.data.data.agents;
      });
      CustomerService.getSkyeAgentList(store);
      UserService.getTeamLeaders();

      if(editUserObj.value && editUserObj.value.round_robin_setting && editUserObj.value.round_robin_setting.user_misc_settings) {
          editUserObj.value.round_robin_setting.user_misc_settings.map((x) => {
            miscSelectedVal.value.push(x.misc_insurance_type);
        })
      }
    })
    
    const onRoleChange = (event) => {
      editUserBodyParam.role_id = event;
      roleValue.value = event;
      rules.value.role.show = false
    };

    const onChangeStatus = (event) => {
      rules.value.underwriter_agent_count.show =
        editUserObj?.value.agentCount > 0 &&
        editUserObj?.value.role_id == 4 &&
        event.target.value == 0
          ? true
          : false;
      editUserBodyParam.status = event.target.value;
      showInactiveTransfer.value =
        editUserBodyParam.status == "0" ? true : false;
      const data = saleagent.value;
      salesAgentList.value = data.filter((agent) => {
        return agent.value !== editUserObj.value.id;
      });
      if (event.target.value == "0") {
        agentList.value = {
          mode: "tags",
          data: [],
          options: salesAgentList,
          searchable: true,
          createTag: true,
        };
      }
    };

    const onChangePolicyAgent = (event) => {
      editUserBodyParam.policy_agent_id = event;
      rules.value.underwritter.show = false;
    };

    const onChangeIsUnderwriter = () => {
      editUserObj.value.policy_agent_id = 0;
    }

    const onChangeEng = (event) => {
      if (event.target.checked) {
        editUserBodyParam.assign_en_leads = event.target.value;
        rules.value.assign_language.show = false;
      } else {
        editUserBodyParam.assign_en_leads = "0";
        if (
          editUserBodyParam.assign_ar_leads == "0" &&
          editUserBodyParam.assign_en_leads == "0"
        ) {
          rules.value.assign_language.show = true;
        }
      }
    };

    const onChangeAr = (event) => {
      if (event.target.checked) {
        editUserBodyParam.assign_ar_leads = event.target.value;
        rules.value.assign_language.show = false;
      } else {
        editUserBodyParam.assign_ar_leads = "0";
        if (
          editUserBodyParam.assign_ar_leads == "0" &&
          editUserBodyParam.assign_en_leads == "0"
        ) {
          rules.value.assign_language.show = true;
        }
      }
    };
    let show_renewal_dom = ref<boolean>(false);
    let show_self_renewal_dom = ref<boolean>(false);
    const onChangeNew = (event) => {
      editUserObj.value.round_robin_setting.is_new = 1;
      editUserObj.value.round_robin_setting.is_renewal = 0;
      editUserObj.value.round_robin_setting.is_all_deals = 0;
      editUserObj.value.round_robin_setting.is_llr = 0;
      editUserObj.value.round_robin_setting.is_both = 0;
      show_renewal_dom.value = true;
      show_self_renewal_dom.value = true;
    };
    const onChangeRenewal = (event) => {
      editUserObj.value.round_robin_setting.is_renewal = event.target.value;
      editUserObj.value.round_robin_setting.is_new = 0;
      editUserObj.value.round_robin_setting.is_both = 0;
      editUserObj.value.round_robin_setting.is_all_deals = 0;
      show_renewal_dom.value = true;
    };

    const onChangeBoth = (event) => {
      show_renewal_dom.value = true;
      editUserBodyParam.is_both = event.target.value;
    };
    const onChangeLlr = (event) => {
      if (event.target.checked) {
        editUserBodyParam.is_llr = event.target.value;
      } else {
        editUserBodyParam.is_llr = "0";
      }
    };

    const refPassword = ref("");

    const onChangeDeals = (event) => {
      if (event.target.value == 2) {
        // editUserObj.value.round_robin_setting.is_new = "1";
        
        // editUserObj.value.round_robin_setting.is_renewal = 0;
        editUserObj.value.round_robin_setting.is_all_deals = 0;
        // editUserObj.value.round_robin_setting.is_llr = 0;
        // editUserObj.value.round_robin_setting.is_both = 0;

        // editUserBodyParam.is_new = "1";
        // editUserBodyParam.is_renewal = 0;
        // editUserBodyParam.is_both = 0;
        // editUserBodyParam.is_both = 0;
        // editUserObj.value.round_robin_setting.is_all_deals = "0";
        editUserBodyParam.is_all_deals = "0";
      }
      if (event.target.value == 1) {
        editUserObj.value.round_robin_setting.is_all_deals = "1";
        editUserBodyParam.is_all_deals = "1";
        // editUserObj.value.round_robin_setting.is_new = "0";
        // editUserBodyParam.is_new = "0";
      }
    };

    const onInRoundRobin = (event) => {
      roundRobinValue.value = event.target.value;
      if (event.target.value == 0) {
        show_renewal_dom.value = false;
        show_self_renewal_dom.value = false;
      }
      editUserObj.value.round_robin_setting.is_round_robin = event.target.value;
      // editUserObj.value.round_robin_setting.is_both = 0;
      // editUserObj.value.round_robin_setting.skip_round = 0;
      // editUserObj.value.round_robin_setting.is_new =
      //   event.target.value == "1" ? 1 : 0;
      // editUserObj.value.round_robin_setting.is_renewal = 0;
      // editUserObj.value.round_robin_setting.is_all_deals = 0;
      // editUserObj.value.round_robin_setting.is_llr = 0;

      // editUserObj.value.round_robin_setting.assign_car = 0;
      // editUserObj.value.round_robin_setting.assign_misc = 0;
      // editUserObj.value.round_robin_setting.assign_health = 0;

      // editUserObj.value.round_robin_setting.assign_en_leads = 0;
      // editUserObj.value.round_robin_setting.assign_ar_leads = 0;
    };

    const onChangeAssignedLeads = (event:number) => {
      console.log(event)
      editUserObj.value.round_robin_setting.assign_car = editUserBodyParam.assign_car = 0;
      editUserObj.value.round_robin_setting.assign_health = editUserBodyParam.assign_health = 0;
      editUserObj.value.round_robin_setting.assign_misc = editUserBodyParam.assign_misc = 0;
      switch(event) {
        case 1: editUserObj.value.round_robin_setting.assign_car = editUserBodyParam.assign_car = 1; break;
        case 2: editUserObj.value.round_robin_setting.assign_health = editUserBodyParam.assign_health = 1; break;
        case 3: editUserObj.value.round_robin_setting.assign_misc = editUserBodyParam.assign_misc = 1; break;
        default: return 0;
      }
      rules.value.assign_leads_of.show = false;
    };

    const onChangeCar = (event) => {
      if (event.target.checked) {
        editUserBodyParam.assign_car = event.target.value;
        rules.value.assign_leads_of.show = false;
      } else {
        editUserBodyParam.assign_car = "0";
        if (
          editUserBodyParam.assign_health == "0" &&
          editUserBodyParam.assign_misc == "0"
        ) {
          rules.value.assign_leads_of.show = true;
        }
      }
    };

    const onChangeHealth = (event) => {
      if (event.target.checked) {
        editUserBodyParam.assign_health = event.target.value;
        rules.value.assign_leads_of.show = false;
      } else {
        editUserBodyParam.assign_health = "0";
        if (
          editUserBodyParam.assign_car == "0" &&
          editUserBodyParam.assign_misc == "0"
        ) {
          rules.value.assign_leads_of.show = true;
        }
      }
    };

    const onChangeMisc = (event) => {
      if (event.target.checked) {
        editUserBodyParam.assign_misc = event.target.value;
        rules.value.assign_leads_of.show = false;
      } else {
        editUserBodyParam.assign_misc = "0";
        if (
          editUserBodyParam.assign_car == "0" &&
          editUserBodyParam.assign_health == "0"
        ) {
          rules.value.assign_leads_of.show = true;
        }
      }
    };
    
    const rules = ref({
      name: {
        show: false,
        message: "Please enter name",
      },

      email: {
        show: false,
        message: "Please enter valid email address format"
      },
      official_email: {
        show: false,
        message: "Please enter valid official email address"
      },
      password: {
        show: false,
        message: "Please enter password",
      },
      confirm_password: {
        show: false,
        message: "Please enter confirm password",
      },
      password_match: {
        show: false,
        message: "Password do not match",
      },
      role: {
        show: false,
        message: "Please select role",
      },
      underwritter: {
        show: false,
        message: "Please select underwriter",
      },
      roundRobin: {
        show: false,
        message: "Please select round robin",
      },
      assign_leads_of: {
        show: false,
        message: "Please select leads assignment",
      },
      assign_misc_insurance_types: {
        show: false,
        message: "Please select any one insurance type"
      },
      assign_language: {
        show: false,
        message: "Please select language",
      },
      agent_type: {
        show: false,
        message: "Please select agent type",
      },
      team_leader: {
        show: false,
        message: "Please select team leader",
      },
      transfer_agents: {
        show: false,
        message: "Please select agent/s transfer to",
      },
      underwriter_agent_count: {
        show: false,
        message:
          "The underwriter cannot be made inactive because the underwriter is assigned to active agents. Kindly assign new underwriter for these agents and then  inactive this underwriter.",
      },
      roles: {
        show: false,
        message: "The role is required"
      }
    });
    const validateUser = () => {
      var regX =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // console.log("editUserBodyParam", editUserBodyParam);
      // console.log("editUserObj", editUserObj);
      if (rules.value.underwriter_agent_count.show) {
        isFormValid.value = false;
      }
      if (editUserBodyParam.name == "") {
        rules.value.name.show = true;
        isFormValid.value = false;
      }
      if (!regX.test(editUserBodyParam.email)) {
        rules.value.email.show = true;
        isFormValid.value = false;
      }
      if (!regX.test(editUserBodyParam.official_email)) {
        rules.value.official_email.show = true;
        isFormValid.value = false;
      }
      if (editUserBodyParam.role_id == 0) {
        rules.value.role.show = true;
        isFormValid.value = false;
      }
      if (
        refPassword.value != "" &&
        refPassword.value != editUserBodyParam.password_confirmation
      ) {
        rules.value.password_match.show = true;
        isFormValid.value = false;
      }
      if (
        editUserBodyParam.role_id == 2 &&
        editUserBodyParam.team_leader == 0
      ) {
        rules.value.team_leader.show = true;
        isFormValid.value = false;
      }
      if (
        [2,13,9].includes(editUserBodyParam.role_id) &&
        ["1", "0"].includes(editUserBodyParam.is_round_robin)
      ) {
        if (!editUserBodyParam.policy_agent_id) {
          rules.value.underwritter.message = "Please select underwriter";
          rules.value.underwritter.show = true;
          isFormValid.value = false;
        }
        if (editUserBodyParam.role_id !== 4) {
          if (
            (editUserBodyParam.assign_car == "0" ||
              editUserBodyParam.assign_car == "") &&
            (editUserBodyParam.assign_misc == "0" ||
              editUserBodyParam.assign_misc == "") &&
            (editUserBodyParam.assign_health == "0" ||
              editUserBodyParam.assign_health == "")
          ) {
            rules.value.assign_leads_of.show = true;
            isFormValid.value = false;
          }
        }
        if (
          editUserBodyParam.assign_ar_leads == "0" &&
          editUserBodyParam.assign_en_leads == "0"
        ) {
          rules.value.assign_language.show = true;
          isFormValid.value = false;
        }
        if (
          editUserObj.value.customerCount &&
          editUserBodyParam.role_id == 2 &&
          editUserBodyParam.status == "0" &&
          editUserBodyParam.agents.length == 0
        ) {
          rules.value.transfer_agents.show = true;
          isFormValid.value = false;
        }
      }
      if([4].includes(editUserBodyParam.role_id) && !editUserObj.value.round_robin_setting.assign_car && !editUserObj.value.round_robin_setting.assign_health && !editUserObj.value.round_robin_setting.assign_misc) {
        rules.value.assign_leads_of.show = true;
        isFormValid.value = false;
      }
      if (
        [2,13,9,4].includes(editUserBodyParam.role_id)
      ) {
        if(editUserBodyParam.assign_misc == 1) {
          if(editUserBodyParam.misc_insurance_types.length == 0) {
            rules.value.assign_misc_insurance_types.show = true;
            isFormValid.value = false;
          }
        }
      }
    };

    const roleValueObj = ref<any>([]);
    let isFormValid = ref<boolean>(true);
    const isClick = ref(false)
    const updateUser = (editUserObj) => {
      roleValueObj.value = editUserObj.roles;
      // roleValueObj.value.push(editUserObj.role_id);
      isFormValid.value = true;
      editUserBodyParam.id = editUserObj.id;
      editUserBodyParam.name = editUserObj.name;
      editUserBodyParam.email = editUserObj.email;
      editUserBodyParam.official_email = editUserObj.official_email;
      editUserBodyParam.is_underwriter = editUserObj.is_underwriter;
      editUserBodyParam.role_id = editUserObj.role_id;
      editUserBodyParam.other_mobile_number = editUserObj.other_mobile_number;
      editUserBodyParam.office_mobile_number = editUserObj.office_mobile_number;
      editUserBodyParam.is_archived =
        editUserObj.round_robin_setting.is_archived;
        editUserBodyParam.password = refPassword.value;
        editUserBodyParam.roles = roleValueObj.value;
      editUserBodyParam.agents = [];
      for (let index = 0; index < agentList.value.data.length; index++) {
        editUserBodyParam.agents.push(agentList.value.data[index]);
      }
      editUserBodyParam.misc_insurance_types = miscSelectedVal.value;
      
      store.commit(Mutations.SET_USER_ERROR, []);

      // editUserBodyParam.password = editUserObj.password;
      // editUserBodyParam.password_confirmation =
      //   editUserObj.password_confirmation;
      if (
        editUserObj.role_id == 2 ||
        editUserObj.role_id == 9 ||
        editUserObj.role_id == 13 ||
        editUserObj.role_id == 4
      ) {
        editUserBodyParam.is_round_robin =
          editUserBodyParam.is_round_robin != ""
            ? editUserBodyParam.is_round_robin
            : editUserObj.round_robin_setting.is_round_robin;

        editUserBodyParam.policy_agent_id = editUserObj.policy_agent_id;
        editUserBodyParam.assign_en_leads =
          editUserBodyParam.assign_en_leads != ""
            ? editUserBodyParam.assign_en_leads
            : editUserObj.round_robin_setting.assign_en_leads;

        editUserBodyParam.assign_ar_leads =
          editUserBodyParam.assign_ar_leads != ""
            ? editUserBodyParam.assign_ar_leads
            : editUserObj.round_robin_setting.assign_ar_leads;

        editUserBodyParam.is_new =
          editUserBodyParam.is_new != ""
            ? editUserBodyParam.is_new
            : editUserObj.round_robin_setting.is_new;

        editUserBodyParam.is_renewal =
          editUserBodyParam.is_renewal != ""
            ? editUserBodyParam.is_renewal
            : editUserObj.round_robin_setting.is_renewal;
        editUserBodyParam.is_llr =
          editUserBodyParam.is_llr != ""
            ? editUserBodyParam.is_llr
            : editUserObj.round_robin_setting.is_llr;
        editUserBodyParam.is_all_deals =
          editUserBodyParam.is_all_deals != ""
            ? editUserBodyParam.is_all_deals
            : editUserObj.round_robin_setting.is_all_deals;
        editUserBodyParam.assign_car =
          editUserBodyParam.assign_car != ""
            ? editUserBodyParam.assign_car
            : editUserObj.round_robin_setting.assign_car;

        editUserBodyParam.assign_misc =
          editUserBodyParam.assign_misc != ""
            ? editUserBodyParam.assign_misc
            : editUserObj.round_robin_setting.assign_misc;
        editUserBodyParam.assign_health =
          editUserBodyParam.assign_health != ""
            ? editUserBodyParam.assign_health
            : editUserObj.round_robin_setting.assign_health;

        editUserBodyParam.skip_round =
          editUserBodyParam.skip_round != ""
            ? editUserBodyParam.skip_round
            : editUserObj.round_robin_setting.skip_round;

        editUserBodyParam.team_leader =
          editUserBodyParam.team_leader != ""
            ? editUserBodyParam.team_leader
            : editUserObj.team_leader_id;

        // editUserBodyParam.assign_en_leads =
        //   editUserBodyParam.assign_en_leads != ""
        //     ? editUserBodyParam.assign_en_leads
        //     : editUserObj.round_robin_setting.assign_en_leads;

        // editUserBodyParam.assign_ar_leads =
        //   editUserBodyParam.assign_ar_leads != ""
        //     ? editUserBodyParam.assign_ar_leads
        //     : editUserObj.round_robin_setting.assign_ar_leads;

        if (editUserBodyParam.is_both == 3) {
          editUserBodyParam.is_new = editUserBodyParam.is_renewal = "1";
        }
      }
      // else if (editUserObj.role_id == 4) {
      //   editUserBodyParam.is_round_robin = 1;
      //   editUserBodyParam.assign_car = 0;
      //   editUserBodyParam.assign_health = 0;
      //   editUserBodyParam.assign_misc = 0;
      // }

      editUserBodyParam.status =
        editUserBodyParam.status != ""
          ? editUserBodyParam.status
          : editUserObj.status;

      if (editUserBodyParam.status === 1) {
        editUserBodyParam.is_archived = false;
      }
      
      validateUser();
      // console.log("editUser", editUserBodyParam);

      if (isFormValid.value) {
        isClick.value = true
        if (editUserObj.round_robin_setting.is_both == 3) {
          editUserBodyParam.is_new = 1;
          editUserBodyParam.is_renewal = 1;
        }

        UserService.editUser(editUserBodyParam)
        .then(() => {
          setTimeout(() => {
            isClick.value = false
          }, 3000);
        })
        .catch((err) => {
          isClick.value = false
          console.log(err)
        });
      } else {
        isClick.value = false
        focusField()
      }
    };

    const isShowOptions = computed(() => {
      return roleValue.value == "2" ? true : false;
    });

    const isShowRoundRobin = computed(() => {
      return roundRobinValue.value == "1" ? true : false;
    });
    const getUserDomStatus = computed(() => {
      return store.getters.getUserDomStatus;
    });
    const settingError = computed(() => {
      return store.getters.getUserErrors;
    });
    // const isShowRenewal = computed(() => {
    //   return renewalValue.value == "1" ? true : false;
    // });
    let editUserObj = computed(() => {
      const data:any = store.getters.getEditUsers;
      if (!data.round_robin_setting) {
        data.round_robin_setting = {
          assign_ar_leads: false,
          assign_car: 0,
          assign_en_leads: false,
          assign_health: 0,
          assign_misc: 0,
          is_all_deals: false,
          is_llr: false,
          is_new: false,
          is_renewal: false,
          is_round_robin: 0,
          skip_round: 0,
          is_archived: false,
          user_id: data.id,
        };
      }

      // if(!data.roles) {
      //   data.roles = []
      // }

      let roleFiltered = data.roles
       if(data.role_id && data.roles && data.roles.length > 0) {
        roleFiltered = data.roles.filter(x => x !== data.role_id)
      }

      data.roles = roleFiltered

      return data;
    });

    let salesAgentList = ref({});

    let agentList = ref({
      mode: "tags",
      data: [],
      options: salesAgentList,
      searchable: true,
      createTag: true,
    });

    watchEffect(() => {
      editUserBodyParam.status = editUserObj.value.status;
    });

    // console.log("sales agents=======");
    // console.log(salesAgentList.value);
    // console.log(agentList.value.options);
    const isPassword = ref(true);
    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        toNameRef()
      }, 1000);
    }

    const toNameRef = () => {
      const refName:any = inst?.refs?.refName
        refName?.focus() 
    }

    const emailRef = () => {
      const emailRef:any = inst?.refs?.emailRef
        emailRef.focus() 
    }

    const official_emailRef = () => {
      const official_emailRef:any = inst?.refs?.official_emailRef
        official_emailRef.focus() 
    }

    const passwordRef = () => {
      const passwordRef:any = inst?.refs?.passwordRef
        passwordRef.focus() 
    }

    const confirmRef = () => {
      const confirmRef:any = inst?.refs?.confirmRef
        confirmRef.focus() 
    }

    const roleRef = () => {
      const roleRef:any = inst?.refs?.roleRef
        roleRef.focus() 
    }

    const underwriterRef = () => {
      const underwriterRef:any = inst?.refs?.underwriterRef
        underwriterRef.focus() 
    }

    const teamLeaderRef = () => {
      const teamLeaderRef:any = inst?.refs?.teamLeaderRef
        teamLeaderRef.focus() 
    }

    const carRef = () => {
      const carRef:any = inst?.refs?.carRef
        carRef.focus() 
    }

    const englishRef = () => {
      const englishRef:any = inst?.refs?.englishRef
        englishRef.focus() 
    }

    const focusField = () => {
      const { name, email,official_email, password, password_match, role } = rules.value

      if(name.show) {
        toNameRef()
      }

      if(!name.show && email.show) {
        emailRef()
        window.scrollTo(0, 0)
      }

      if(!name.show && !email.show && official_email.show) {
        official_emailRef()
        window.scrollTo(0, 0)
      }

      if(!name.show && !email.show && official_email.show && password.show) {
        passwordRef()
      }

      if(!name.show && !email.show && official_email.show && !password.show && password_match.show) {
        confirmRef()
      }

      if(!name.show && !email.show && official_email.show && !password.show && !password_match.show && role.show) {
        roleRef()
      }

      if([2, 9].includes(editUserObj.value.role_id)) {
        salesAgent()
      }

      if(editUserObj.value.role_id === 4) {
        underwriter()
      }
    }

    const salesAgent = () => {
      const  { underwritter, team_leader, assign_leads_of, assign_language } = rules.value 

      if(underwritter.show) {
        underwriterRef()
      }

      if(!underwritter.show && team_leader.show) {
        teamLeaderRef()
      }

      if(!underwritter.show && !team_leader.show && assign_leads_of.show) {
        carRef()
      }

      if(!underwritter.show && !team_leader.show && !assign_leads_of.show && assign_language.show) {
        englishRef()
      }
    }

    const underwriter = () => {
      const  { assign_leads_of } = rules.value 

      if(assign_leads_of.show) {
        carRef()
      }
    }

    const rolesValues = computed(() => {
      const data = store.state.UserModule.roles;
      if(data) return data.filter(x => x.id != editUserObj.value.role_id)

      return [];
    })

    const otherInsuranceTypesList:any = computed(() => {
      return [
            { id: 1, name: 'Home' },
            { id: 2, name: 'Jet Ski' },
            { id: 3, name: 'Pet' },
            { id: 4, name: 'Travel' },
            { id: 5, name: 'Wedding' },
            { id: 6, name: 'Yacht' },
        ]
    });
    
    return {
      miscSelectedVal,
      otherInsuranceTypesList,
      // errorMessage,
      // value,
      isClick,
      focusField,
      toFirst,
      role,
      isFormValid,
      isPassword,
      saleagent,
      editUserBodyParam,
      onRoleChange,
      updateUser,
      onInRoundRobin,
      onChangePolicyAgent,
      onChangeEng,
      isShowOptions,
      roleValue,
      isShowRoundRobin,
      roundRobinValue,
      // renewalValue,
      onChangeRenewal,
      onChangeStatus,
      onChangeNew,
      onChangeAr,
      onChangeLlr,
      onChangeDeals,
      onChangeCar,
      onChangeHealth,
      onChangeMisc,
      editUserObj,
      onChangeBoth,
      agentList,
      salesAgentList,
      show_renewal_dom,
      show_self_renewal_dom,
      showInactiveTransfer,
      getUserDomStatus,
      settingError,
      rules,
      onChangeAssignedLeads,
      refPassword,
      rolesValues,
      onChangeIsUnderwriter
    };
  },
});
